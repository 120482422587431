import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import {
  fetchGetClientsNodes,
  fetchUndeliveredAwb,
} from "../../../redux/api/expedition.api";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getUndeliveredAwbTableData,
  undeliveredAwbTableHeader,
} from "./tableConfig/undeliveredAwbTableConfig";
import UndeliveredAwbTableActionButtons from "./components/TableActionButtonComponent/UndeliveredAwbTableActionButtons";
import { useEffect, useMemo } from "react";
import { clearWorkingRoutesNodes } from "../../../redux/reducer/expedition.reducer";

function UndeliveredAwb() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      undeliveredAwb: {
        data: { data: dataUndeliveredAwb, total: totalUndeliveredAwb },
        isLoading: isLoadingAwb,
      },
      workingRoutesNodes: {
        data: { data: clientsNodesData },
        isLoading: isLoadingNodes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(
      fetchGetClientsNodes({
        page: 1,
        perPage: 50,
      }),
    );

    return () => {
      dispatch(clearWorkingRoutesNodes());
    };
  }, [dispatch]);

  const handleAwbTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch &&
      action.tableFilter
    ) {
      dispatch(
        fetchUndeliveredAwb({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          pctlivrare: action.tableSearch.pctlivrare,
          nodDestinatie: action.tableFilter.nodDestinatie,
          doc_trans: action.tableSearch.doc_trans,
        }),
      );
    }
  };

  const undeliveredAwbTableHeaderNew = useMemo(
    () =>
      undeliveredAwbTableHeader.map((item) => {
        if (!isLoadingNodes && item.value === "nodDestinatie") {
          const nodesDropdown = clientsNodesData?.map(
            ({ nodNume, id_nod }) => ({
              label: nodNume,
              value: id_nod,
            }),
          );
          return {
            withFilter: [{ label: "Toate", value: 0 }, ...nodesDropdown],
            ...item,
          };
        }
        return item;
      }),
    [clientsNodesData, isLoadingNodes],
  );

  return (
    <TableWidget
      tableTitle="AWB-uri nelivrate"
      tableHeaderData={undeliveredAwbTableHeaderNew}
      tableItemsData={getUndeliveredAwbTableData(dataUndeliveredAwb)}
      totalItems={totalUndeliveredAwb}
      tableLoading={isLoadingAwb}
      handleTableActions={handleAwbTableActions}
      ActionButtonsComponent={UndeliveredAwbTableActionButtons}
      borderedRow
    />
  );
}

export default UndeliveredAwb;
