import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";
import { AddAllDaysItemScheduleInterface } from "../../pages/Expedition/components/ConfigWorkingDays";

import { DropdownValue } from "../../components/misc/Dropdown";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const getScheduleWorkingPoints = createAsyncThunk(
  "/expedition/scheduleWorkingPoints",
  async (
    params: {
      page?: number;
      perPage?: number;
      deliveryPoint: string;
      route: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, deliveryPoint } = params;

    try {
      const { data } = await apiRequest(
        `/nomPuncteLucru?page=${page}&perPage=${perPage}&searchPctLucru=${deliveryPoint}&searchRuta=${params.route}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/scheduleWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScheduleWorkingPointsDetails = createAsyncThunk(
  "/expedition/getScheduleWorkingPointsDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectPctLucru/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getScheduleWorkingPointsDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateWorkingPoint = createAsyncThunk(
  "/expedition/postCreateOrUpdateWorkingPoint",
  async (
    params: {
      id: number;
      adresa: string;
      denumire: string;
      localitate: string;
      judet: string;
      cod_postal: string;
      pers_contact: string;
      tel_contact: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/createOrUpdatePctLucru",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/postCreateOrUpdateWorkingPoint", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutes = createAsyncThunk(
  "/expedition/getWorkingRoutes",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage } = params;

    try {
      const { data } = await apiRequest(
        `/nomRute?page=${page}&perPage=${perPage}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutesDetails = createAsyncThunk(
  "/expedition/getWorkingRoutesDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectRuta/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutesDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postScheduleRoutes = createAsyncThunk(
  "/expedition/postScheduleRoutes",
  async (
    params: {
      activeWorkingDays: AddAllDaysItemScheduleInterface[];
      internode: boolean;
      id: number;
      selectedNode: DropdownValue;
      name: string;
      code: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/createOrUpdateRuta", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/postScheduleRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchGetClientsNodes = createAsyncThunk(
  "/expedition/fetchGetClientsNodes",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/displayNoduri", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/fetchGetClientsNodes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditNodeDetails = createAsyncThunk(
  "/expedition/postEditNodeDetails",
  async (
    {
      callBack,
      params,
    }: {
      callBack?: () => void;
      params: {
        nodId: number;
        auto: string;
        sofer: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/editNod", "POST", params);
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/expedition/postEditNodeDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUndeliveredAwb = createAsyncThunk(
  "/fetchUndeliveredAwb",
  async (
    params: {
      page: number;
      perPage: number;
      pctlivrare?: string;
      nodDestinatie?: string;
      doc_trans?: string;
    },
    { rejectWithValue, getState },
  ) => {
    const state: any = getState();

    const {
      user: {
        data: { id_client },
      },
    } = state;

    try {
      const { data } = await apiRequest("/displayUndeliveredAwbs", "GET", {
        ...params,
        id_client,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchUndeliveredAwb", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const postAssociateAwbToBin = createAsyncThunk(
  "/expedition/postAssociateAwbToBin",
  async (
    {
      params,
    }: {
      callBack?: () => void;
      params: {
        id_doctrans: number | string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/associateAwbToBin", "POST", params);

      return data;
    } catch (err: any) {
      console.warn("/expedition/postAssociateAwbToBin", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchPrintA4 = createAsyncThunk(
  "/expedition/fetchPrintA4",
  async (
    {
      idBin,
    }: {
      idBin?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/printSnBinA4/${idBin}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/expedition/fetchPrintA4", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchPrintZebra = createAsyncThunk(
  "/expedition/fetchPrintZebra",
  async (
    {
      idBin,
    }: {
      idBin?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/printSnBinZebra/${idBin}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/expedition/fetchPrintZebra", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
